<template>
	<v-card
		:class="modifier"
		:to="anchor"
		flat
		class="service-card"
	>
		<v-card-title class="service-card__title">
			<v-icon class="service-card__title-icon">
				{{ icon }}
			</v-icon>
			<h3 class="service-card__title-heading">
				{{ title }}
			</h3>
		</v-card-title>
		<v-card-text class="service-card__text">
			<p class="service-card__text-description">
				{{ description }}
			</p>
		</v-card-text>
		<v-card-actions class="service-card__actions">
			<v-btn
				class="service-card__actions-btn"
				large
				rounded
				outlined
			>
				{{ cta }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: 'ServicesCard',
	props: {
		modifier: {
			type: String,
			required: true
		},
		anchor: {
			type: String,
			required: true
		},
		icon: {
			type: String,
			required: true
		},
		title: {
			type: String,
			required: true
		},
		description: {
			type: String,
			required: true
		},
		cta: {
			type: String,
			required: true
		}
	}
};
</script>

<style scoped lang="scss">
.service-card {
	height: 100%;
	margin: 1px 1px gutter-size(1);
	padding: gutter-size(2);
	&__title {
		flex-direction: column;
	}
	&__title-icon {
		color: rgba(0, 0, 0, 0.7);
		font-size: 48px;
		height: 48px;
		margin-bottom: gutter-size(4);
	}
	&__title-heading {
		color: rgba(0, 0, 0, 0.7);
		font-family: $heading-font-family;
		font-size: $font-size-root * 1.333;
		font-weight: map-get($font-weights, 'regular');
		line-height: $line-height-sm;
		min-height: 64px;
		text-align: center;
		text-transform: uppercase;
		word-break: break-word;
	}
	&__text {
		padding-top: gutter-size(2);
		text-align: center;
	}
	&__text-description {
		color: rgba(0, 0, 0, 0.7);
		font-size: $font-size-sm;
		margin: 0;
		min-height: 99px;
	}
	&__actions {
		justify-content: center;
		padding-bottom: gutter-size(4);
	}
	&__actions-btn {
		color: rgba(0, 0, 0, 0.7);
	}

	&--autism {
		@include serviceCard(var(--v-autism-lighten5), var(--v-autism-base), var(--v-autism-darken4));
	}

	&--school {
		@include serviceCard(var(--v-school-lighten5), var(--v-school-base), var(--v-school-darken4));
	}

	&--intellectual {
		@include serviceCard(var(--v-intellectual-lighten5), var(--v-intellectual-base), var(--v-intellectual-darken4));
	}

	&--academic {
		@include serviceCard(var(--v-academic-lighten5), var(--v-academic-base), var(--v-academic-darken4));
	}

	&--attention {
		@include serviceCard(var(--v-attention-lighten5), var(--v-attention-base), var(--v-attention-darken4));
	}

	&--comprehensive {
		@include serviceCard(var(--v-comprehensive-lighten5), var(--v-comprehensive-base), var(--v-comprehensive-darken4));
	}

	#{$interact} {
		.service-card__actions-btn {
			background: rgba(255, 255, 255, 0.33);
		}
	}
}
</style>
