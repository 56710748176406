<template>
	<div class="social">
		<v-btn
			outlined
			color="#4267b2"
			class="social__btn"
			role="button"
			@click="shareFacebook()"
		>
			share
			<v-icon class="social__icon">
				$vuetify.icons.facebook
			</v-icon>
		</v-btn>
		<v-btn
			outlined
			color="#0E9AF1"
			class="social__btn"
			role="button"
			@click="shareTwitter()"
		>
			tweet
			<v-icon class="social__icon">
				$vuetify.icons.twitter
			</v-icon>
		</v-btn>
	</div>
</template>

<script>
export default {
	name: 'Social',
	methods: {
		shareFacebook() {
			window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(document.URL)}&t=${encodeURIComponent(document.URL)}`);
		},
		shareTwitter() {
			window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(document.title)} ${encodeURIComponent(document.URL)}`);
		}
	}
};
</script>

<style scoped lang="scss">
.social {
	display: flex;
	justify-content: center;
	margin: 10px 0;
	&__btn {
		margin: 0 0 0 gutter-size(4);
	}
	&__icon {
		font-size: $font-size-lg;
		margin-left: gutter-size(3);
	}
	@media only screen and (min-width: #{map-get($grid-breakpoints, 'sm')}) {
		justify-content: flex-end;
	}
}
</style>
