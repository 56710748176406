<template>
	<v-parallax
		:alt="alt"
		:height="height"
		:src="require(`@/img/parralax-large.webp`)"
		class="parallax-image"
	>
		<slot />
	</v-parallax>
</template>

<script>
export default {
	name: 'ParallaxImage',
	props: {
		alt: {
			type: String,
			required: true
		},
		height: {
			type: Number,
			required: true
		}
	}
};
</script>

<style scoped lang="scss">
.parallax-image {
	background-color: $grey-lighter;
	padding: 60px 0;
}
</style>
